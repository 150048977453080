<template>
  <div class="d-flex msgContent">
    <div class="d-flex msgLabel w50">
      <div class="msgName">资格审核</div>
      <div
        class="msgVal line1 flexList line-height-45"
        v-if="examInfo.sfxyzgjgsh"
        :class="{
          'text-danger': form.zgshzt == 2,
          'text-success': form.zgshzt == 1,
          'text-warning': form.zgshzt == 9,
        }"
      >
        {{
          form.zgshzt == 1
            ? "已通过"
            : form.zgshzt == 2
            ? "未通过，" + form.zgshsm
            : form.zgshzt == 9
            ? "已锁定"
            : "未审核"
        }}
        <span class="text-danger" v-if="form.zgshzt == 2">（修改报名信息或重新填报，须删除后重新提交）</span>
      </div>
      <div
        class="msgVal line1 flexList line-height-45"
        v-if="!examInfo.sfxyzgjgsh"
      >
       不审核
      </div>
    </div>
    <div class="d-flex msgLabel w50">
      <div class="msgName">照片审核</div>
      <div
        class="msgVal line1 flexList line-height-45"
        :class="{
          'text-danger': form.zpshzt == 2,
          'text-success': form.zpshzt == 1,
        }"
      >
        {{
          form.zpshzt == 1
            ? "已通过"
            : form.zpshzt == 2
            ? "未通过" + from.zpshsm
            : "未审核"
        }}
      </div>
    </div>
    <div class="d-flex msgLabel w50">
      <div class="msgName">缴费信息</div>
      <div class="msgVal line1 flexList line-height-45" >
        <span
          :class="{
            'text-danger': form.jfzt == 9,
            'text-success': form.jfzt == 1,
            'text-info': form.jfzt == 2,
          }"
        >
          {{
            form.jfzt == 1
              ? "已缴费"
              : form.jfzt == 0
              ? "未缴费"
              : form.jfzt == 2
              ? "免收费"
              : form.jfzt == 9
              ? "已退费"
              : "未缴费"
          }}
        </span>
      </div>
    </div>
    <div class="d-flex msgLabel w50">
      <div class="msgName">缴费金额</div>
      <div class="msgVal line1 flexList line-height-45">
        {{ form.yjfje / 100 + "元" }}
        <!-- <span class="mr-3">
          {{ "应缴费金额：" + form.yjfje / 100 + "元" }}
        <span>
          {{ "实缴费金额：" + form.sjfje / 100 + "元" }}
        </span> -->
      </div>
    </div>
    <div class="d-flex msgLabel w100">
      <div class="msgName">审核备注</div>
      <div class="msgVal line1 flexList">
        {{ form.zgshbz}}
      </div>
    </div>
  </div>
</template>

<script>
import { getUserExamFree } from "@/api/examination.js";
export default {
  model: {
    prop: "form",
    event: "input",
  },
  props: {
    ksbmbh: {
      type: String,
    },
    form: Object,
    examInfo:Object
  },
  watch: {

  },
  data() {
    return {
      dataList: [
        {
          name: "资格审核",
          zddm: "msyy",
          width: "50",
        },
        {
          name: "照片审核",
          zddm: "zsmc",
          width: "50",
        },
        {
          name: "缴费信息",
          zddm: "zsbh",
          width: "50",
        },
      ],
    };
  },
  methods: {

  },
};
</script>

<style>
.w50 {
  width: 50%;
}

.w100 {
  width: 100%;
}
.line-height-45{
  line-height: 45px !important;
}
</style>
