<style type="text/css">
@import "~@/assets/css/common.css";
@import "~@/assets/css/admin.css";

@import "~@/assets/css/enroll-form.css";
</style>
<style scoped>
  html,body{
    font-size: 16px;
  }
.pay-money {
  height: unset;
}
.print {
  text-align: center;
}
/deep/ .msgName {
  background-color: #ffffff !important;
}
.pay-money {
  border: 1px solid #dfdfdf;
  margin-top: -1px;
  margin-left: -1px;
  padding: 0 10px;
}
</style>
<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import FormHeader from "@/views/pages/examinee/form-header.vue";
import draggable from "vuedraggable";
import examForm from "@/components/form/examForm";

import registerInfo from "@/components/examInfo/registerInfo";
import freeExam from "@/components/examInfo/freeExam";
import statusInfo from "@/components/examInfo/statusInfo";
import { noChange } from "@/assets/js/nochange.js"

import $ from "jquery";
import {
  getExamInfo,
  examApply,
  examEdit,
  getUserExamOne,
  getUserBmk,
  getUserExamFree,
  getbmkskm,
  userSpecial,
} from "@/api/examination.js";
import { getUserInfo } from "@/libs/common";
/**
 * 报名信息提交
 */
export default {
  components: {
    Layout,
    PageHeader,
    FormHeader,
    draggable,
    examForm,
    registerInfo,
    freeExam,
    statusInfo,
  },
  data() {
    return {
      title: "报名信息提交",
      items: [
        {
          text: "考试报名",
        },
        {
          text: "报名详情",
          active: true,
        },
      ],
      userInfo: {},
      query: {},
      examInfo: {},
      form: {
        gznxzm: "",
        xlzm: "",
        qtzm: "",
      },
      readOnly: true,
      msData: {},
      cwzddmList: [],
      bmkmList: [],
      specialData: {}, //特殊库信息
      pageHeight: 1103,
      pdfHeight: 0,
      pdfNum: 1,
      row: 0,
      oldHeight: 0,
    };
  },
  methods: {
    print() {
      $("#printId").css("display", "");
      // this.toPdfHtml("printId", "printBmb", "applyInfo");
      this.ExportSavePdf("报名信息表", "printId", 0, true);
    },

    toPdfHtml(id, id1, id2) {
      let width = $("#" + id).outerWidth();
      this.pageHeight = Math.floor((width / 210) * 297);
      if ($("#" + id1).length > 0) {
        return false;
      }
      this.pdfNum = 1;
      this.pdfHeight = 0;
      $("#" + id).append("<div id='" + id1 + "'></div>");
      $("#" + id1).append(
        "<div class='pdf' id='" +
          id1 +
          "1' page='1' style='height:" +
          this.pageHeight +
          "px'></div>"
      );
      // 考试规则
      if ($("#" + id2 + " .ksTitle").length > 0) {
        this.addItem(
          $("#" + id2 + " .ksTitle"),
          $("#" + id2 + " .ksTitle").outerHeight(true),
          id1
        );
      }
      if ($("#" + id2 + " .jbxx").length > 0) {
        this.addItem(
          $("#" + id2 + " .jbxx .title"),
          $("#" + id2 + " .jbxx .title").outerHeight(true),
          id1
        );
        let itemhead = $("#" + id2 + " .jbxx .value").find(
          ".person-form.outerCotent>.msgContent>div:first-child"
        );
        this.addItem($(itemhead), $(itemhead).outerHeight(true), id1);
        let itemfoot = $("#" + id2 + " .jbxx .value").find(
          ".person-form.outerCotent>.msgContent>div:last-child"
        );
        $(itemfoot)
          .children()
          .children()
          .each((i, item) => {
            this.addItem(item, $(item).outerHeight(), id1);
          });
      }
      if ($("#" + id2 + " .ksbz").length > 0) {
        this.addItem(
          $("#" + id2 + " .ksbz"),
          $("#" + id2 + " .ksbz").outerHeight(true),
          id1
        );
      }
      if ($("#" + id2 + " .bkxx").length > 0) {
        this.addItem(
          $("#" + id2 + " .bkxx"),
          $("#" + id2 + " .bkxx").outerHeight(true),
          id1
        );
      }
      if ($("#" + id2 + " .msxx").length > 0) {
        this.addItem(
          $("#" + id2 + " .msxx"),
          $("#" + id2 + " .msxx").outerHeight(true),
          id1
        );
      }
      if ($("#" + id2 + " .bmzt").length > 0) {
        this.addItem(
          $("#" + id2 + " .bmzt"),
          $("#" + id2 + " .bmzt").outerHeight(true),
          id1
        );
      }
    },
    addItem(item, height, id, imgurl) {
      console.log(height);
      let paddingHeight = 60;
      height = Number(height);
      if (
        $(item).hasClass("msgLabel") &&
        !$(item).hasClass("w100") &&
        this.row == 0
      ) {
        this.row = 1;
      } else if (
        $(item).hasClass("msgLabel") &&
        !$(item).hasClass("w100") &&
        this.row != 0
      ) {
        this.oldHeight = height;
        this.row = 0;
        height = 0;
      }
      if (
        $(item).hasClass("msgLabel") &&
        $(item).hasClass("w100") &&
        this.row != 0
      ) {
        // this.pdfHeight += this.oldHeight;
        this.oldHeight=0
        this.row = 0;
      }
      // 当前pdf高度大于0，且未添加新对象高度前后的页数不一致则换页
      if (
        this.pdfHeight > 0 &&
        parseInt((this.pdfHeight + paddingHeight) / this.pageHeight) !=
          parseInt((this.pdfHeight + height + paddingHeight) / this.pageHeight)
      ) {
      console.log(this.pdfHeight);
        // pdf页数加1
        this.pdfNum += 1;
        this.pdfHeight = (this.pdfNum - 1) * this.pageHeight;
        $("#" + id).append(
          "<div class='pdf' id='" +
            id +
            this.pdfNum +
            "' page='" +
            this.pdfNum +
            "'style='height:" +
            this.pageHeight +
            "px'></div>"
        );
      }

      this.pdfHeight += height;
      // 将内容放入div中
      if ($(item).hasClass("msgLabel")) {
        if ($("#" + id + this.pdfNum + " .flexBox").length) {
          $(item)
            .clone()
            .appendTo($("#" + id + this.pdfNum + " .flexBox"));
        } else {
          $("#" + id + this.pdfNum).append(
            "<div class='d-flex flex-wrap flexBox'></div>"
          );
          $(item)
            .clone()
            .appendTo($("#" + id + this.pdfNum + " .flexBox"));
        }
      } else {
        $(item)
          .clone()
          .appendTo($("#" + id + this.pdfNum));
      }

      // $("#" + id + " .pdf").css("border", "1px #000000 solid");
      $("#" + id + " .pdf").css("padding", "30px 25px");
      $("#" + id + " .pdf").css("color", "black");
      if (imgurl) {
        $("#" + id + " .pdf").css("background-image", "url(" + imgurl + ")");
      }
    },

    getExamInfo() {
      getExamInfo(this.query.ksbmbh).then((res) => {
        if (res.status) {
          this.examInfo = res.data;
          console.log(res.data)
          if (this.examInfo.sfqytsmdk) {
            this.getSpecial();
          } else {
            this.init(this.query.bmh);
          }
        }
      });
    },

    getUserInfo() {
      getUserInfo().then((data) => {
        this.userInfo = data;
        return;
      });
    },

    getmsData() {
      getUserExamFree().then((res) => {
        if (res.status) {
          this.msData = res.data.filter((v) => {
            return v.ksbmbh == this.examInfo.ksbmbh;
          })[0];
        }
      });
    },
    init(bmh) {
      getUserExamOne(bmh + "?isShowId=1").then((res) => {
        if (res.status) {
          let data = res.data[0] || "";
          if (data) {
            if (data.mssqzt != -1) {
              this.getmsData();
            }
            if (this.specialData.json) {
              let obj = JSON.parse(this.specialData.json);
              for (const key in obj) {
                if (!data[key]) {
                  data[key] = obj[key];
                }
              }
            }
            this.form = data;
            // if (this.examInfo.bmkbm) {
              getUserBmk(this.query.ksbmbh).then((res) => {
                if (res.status) {
                  let list = res.data[res.data.length - 1] || {};
                  for (const key in list) {
                    if (!data[key]) {
                      data[key] = list[key];
                    }
                  }
                  this.form = data;
                }
              });
            // } else {
            //   this.form = data;
            // }
            //判断是否可以修改
            if (data.cwzddm && data.sfyxxg && !data.sfzstj) {
              if (
                new Date().getTime() <
                new Date(data.yxxgsj.replace(/-/g, "/")).getTime()
              ) {
                this.cwzddmList = data.cwzddm.split("、");
              }
            }
            setTimeout(() => {
              this.$nextTick(() => {
                return new Promise((res) => {
                  this.toPdfHtml("printId", "printBmb", "applyInfo");
                  $("#applyInfo").css("display", "none")
                });
              });
            }, 2000);
          }
        }
      });
    },
    getBase64Image(url, ref) {
      var that = this;
      var image = new Image();
      image.src = url; // 处理缓存
      image.crossOrigin = "*"; // 支持跨域图片
      image.onload = function () {
        var base64 = that.drawBase64Image(image);
        //转换后的赋值回去，这个是通过获取节点赋值的方式
        that.refs.targetDom;
        //调用引入的js方法调取打印
        printOut(targetDom);
      };
    },
    //查询报名库
    getBmk() {
      getUserBmk(this.query.ksbmbh).then((res) => {
        if (res.status) {
          let obj = res.data[0] || {};
          for (const key in obj) {
            if (!this.form[key]) {
              this.form[key] = obj[key];
            }
          }
        }
      });
    },
    //查询特殊库
    getSpecial() {
      userSpecial().then((res) => {
        if (res.status) {
          this.init(this.query.bmh);
          let list = res.data;
          if (list && list.length) {
            let tsData = list.filter((v) => {
              return v.ksbmbh == this.examInfo.ksbmbh;
            })[0];
            if (tsData) {
              this.specialData = tsData;
            }
          }
        }
      });
    },
  },
  created() {
    this.query = this.$route.query;
    this.getExamInfo();
    getbmkskm(this.query.bmh).then((res) => {
      if (res.status) {
        this.bmkmList = res.data;
      }
    });
    this.$nextTick(() => {
      noChange()
    });
  },
};
</script>

<template>
  <Layout>
    <!-- <PageHeader :title="title" :items="items" /> -->
    <div class="print mb-3">
      <button class="btn btn-info" @click="print">打印</button>
    </div>
    <div class="container card">
      <div class="row mx-auto " id="applyInfo" style="padding: 30px 25px">
        <div class="col-lg-12">
          <div class="card" style="min-height: 470px">
            <div class="card-body">
              <div class="ksTitle">
                <div
                  class="page-title-box form-red-title d-flex align-items-center justify-content-center"
                >
                  {{ examInfo.dymc }}
                </div>
                <div class="d-flex justify-content-end">
                  <span class="ml-3" style="font-size: 14px">
                    报名时间：{{ form.createTime }}
                  </span>
                </div>
              </div>
              <div class="jbxx">
                <div
                  class="pay-money d-flex align-items-center justify-content-between pt-3 pb-3 title"
                  style="border-top: 1px solid #dfdfdf"
                >
                  <div>
                    <span>基本信息</span>
                  </div>
                </div>
                <div class="msgContent flexList fs-xs value">
                  <examForm
                    v-model="form"
                    :examInfo="examInfo"
                    :bmh="form.bmh"
                    :ksbmbh="query.ksbmbh"
                    :readOnly="readOnly"
                  ></examForm>
                </div>
              </div>
              <div v-if="examInfo.ksbzxx" class="ksbz mt-3" style="white-space: pre-wrap" >
                <div>{{ examInfo.ksbzxx }}</div>
              </div>
              <div
                class="bkxx mt-3"
                v-if="form.kmxzfs == 1 && examInfo.kslxmc != '其他申请报名'"
              >
                <div class="pay-money pt-3 pb-3">报考信息</div>
                <div class="">
                  <div class="outerCotent person-form">
                    <registerInfo v-model="form"></registerInfo>
                  </div>
                </div>
              </div>

              <div
                class="msxx mt-3"
                v-if="form.mssqzt != '-1' && form.mssqzt != null"
              >
                <div class="pay-money pt-3 pb-3">免试信息</div>
                <div
                  class="outerCotent person-form"
                  v-if="examInfo.kmxzfs == '1'"
                >
                  <freeExam v-model="form"></freeExam>
                </div>
              </div>
              <div class="bmzt mt-3">
                <div class="pay-money pt-3 pb-3">报名状态</div>
                <div>
                  <statusInfo v-model="form" :examInfo="examInfo"></statusInfo>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="printId" style=""></div>
    </div>
  </Layout>
</template>
